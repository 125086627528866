export const contactFormData = [
  {
    label: "First Name:",
    id: "firstName",
  },
  {
    label: "Last Name:",
    id: "lastName",
  },
  {
    label: "Phone Number:",
    id: "phoneNumber",
  },
  {
    label: "Email:",
    id: "email",
    type: "email",
  },
  {
    label: "Organization",
    id: "organization",
  },
  {
    label: "Job Title:",
    id: "jobTitle",
  },
  {
    label: "Estimated Budget:",
    id: "estimatedBudget",
    type: "radio",
    size: "large",
    options: [
      {
        label: "$10,000 - $19,000",
        value: "10000-19000",
      },
      {
        label: "$20,000 - $49,000",
        value: "20000-49000",
      },
      {
        label: "$50,000 - $99,000",
        value: "50000-99000",
      },
      {
        label: "$100,000+",
        value: "100000+",
      },
    ],
  },
  {
    label: "Brief Description of the Work:",
    id: "workDescription",
    type: "textarea",
    size: "large",
  },
]
