import React from "react"
import { CloseIcon } from "./icons"
import Form from "./ContactUs/contactForm"
import Modal from "./modal"

const ContactFormModal = ({ toggle }) => {
  return (
    <Modal close={toggle} color="black">
      <div className="relative overflow-y-scroll text-white md:flex custom-scroll">
        <Form />
      </div>
      <div
        className="absolute p-2 transition-all duration-300 bg-white rounded-full cursor-pointer top-1/50 md:top-1/20 right-1/30 hover:bg-gray-300"
        onClick={() => toggle(false)}
      >
        <CloseIcon color="#000" width={15} height={15} />
      </div>
    </Modal>
  )
}

export default ContactFormModal
