import React, { useState } from "react"
import { Link } from "gatsby"
import SVG, { Props as SVGProps } from "react-inlinesvg"
// Components
import { DownIcon, MobMenu, CloseIcon } from "./icons"

// Images
import logo from "../assets/logo.svg"
// import BgImg from "../assets/StPatricksDayBG.png"
import CustomNavItem1 from "../assets/our-company-customNav.svg"
import CustomNavItem2 from "../assets/services-customNav.svg"
import CustomNavItem3 from "../assets/pricing-customNav.svg"
import CustomNavItem4 from "../assets/for-government-customNav.svg"
import CustomNavItem5 from "../assets/our-work-customNav.svg"
import CustomNavItem6 from "../assets/blog-customNav.svg"
import HolidayBannerImage from "../assets/holiday-banner-image.svg"
import HolidayBannerImageMobile from "../assets/holiday-banner-image-mobile.svg"

const Header = ({ customNav = false, topBanner, banner }) => {
  const [navToggle, setNavToggle] = useState(false)

  const navOptions = [
    {
      title: "Our Company",
      img: CustomNavItem1,
      route: "/our-company",
    },
    {
      title: "Services",
      img: CustomNavItem2,
      route: "/services",
      nestedLinks: [
        // { title: "Marketing & Branding", route: "/services/marketing" },
        {
          title: "Website Development",
          route: "/services/website-development",
        },
        { title: "Video Production", route: "/services/video-production" },
        {
          title: "Graphic Design & Animation",
          route: "/services/graphic-design",
        },
        {
          title: "Report Design",
          route: "/services/report-design-agency",
        },
      ],
    },
    {
      title: "Pricing",
      img: CustomNavItem3,
      route: "/pricing",
    },
    {
      title: "For Government",
      img: CustomNavItem4,
      route: "/government",
    },
    {
      title: "Our Work",
      img: CustomNavItem5,
      route: "/our-work",
    },
    {
      title: "Blog",
      img: CustomNavItem6,
      route: "/blog",
    },
  ]
  const mobileNav = [
    {
      title: "Our Work",
      route: "/our-work",
    },
    {
      title: "Our Company",
      route: "/our-company",
    },
    {
      title: "Services",
      route: "/services",
      nestedLinks: [
        // { title: "Marketing & Branding", route: "/services/marketing" },
        {
          title: "Website Development",
          route: "/services/website-development",
        },
        { title: "Video Production", route: "/services/video-production" },
        {
          title: "Graphic Design & Animation",
          route: "/services/graphic-design",
        },
        {
          title: "Report Design",
          route: "/services/report-design-agency",
        },
      ],
    },
    {
      title: "Pricing",
      route: "/pricing",
    },
    {
      title: "For Government",
      route: "/government",
    },
    {
      title: "Blog",
      route: "/blog",
    },
  ]
  const customBannerText =
    "This week we remember and honor those who made the ultimate sacrifice."

  return (
    <>
      {customNav && topBanner && (
        <div className="custom-banner">
          <p>{customBannerText}</p>
        </div>
      )}
      <div
        className={`bg-black nav ${customNav ? "custom-nav" : ""} ${
          topBanner ? "with-banner" : ""
        }`}
      >
        <div className="relative flex items-center justify-between max-w-6xl px-3 py-2 mx-auto md:py-3 md:px-2 lg:px-8 xl:px-0">
          <div className="flex-1">
            <div className="w-24">
              <Link to="/">
                <img src={logo} alt="Kokopelli Logo" />
              </Link>
            </div>
          </div>

          <div className={"hidden lg:flex md:flex-3 md:justify-end"}>
            {navOptions.map((item, index) => {
              return item.nestedLinks ? (
                // Dropdown nav items
                <div
                  className={`flex dropdown ${
                    !customNav ? "lg:px-3" : "xl:px-2"
                  }`}
                  key={index}
                >
                  <Link
                    to={item.route}
                    activeClassName="text-primary"
                    className="flex my-auto text-lg text-white capitalize cursor-pointer hover:text-primary siteFont"
                  >
                    {customNav ? <SVG src={item.img} /> : item.title}
                  </Link>
                  <div className="drop-item">
                    <div className="item-group">
                      {item.nestedLinks.map((link, index) => (
                        <Link
                          to={link.route}
                          key={index}
                          className="text-lg text-white capitalize hover:text-primary siteFont"
                        >
                          {link.title}
                        </Link>
                      ))}
                    </div>
                  </div>
                  {!customNav && (
                    <div className="inline-block p-1 my-auto ml-2 font-bold border border-gray-800 rounded-full hover:cursor-pointer">
                      <DownIcon width="8" height="8" />
                    </div>
                  )}
                </div>
              ) : (
                // Nav items
                <div
                  className={`flex ${!customNav ? "lg:px-3" : "xl:px-2"}`}
                  key={index}
                >
                  <Link
                    to={item.route}
                    activeClassName="text-primary"
                    className="my-auto text-lg text-white capitalize cursor-pointer md:text-lg hover:text-primary siteFont"
                  >
                    {customNav ? <SVG src={item.img} /> : item.title}
                  </Link>
                </div>
              )
            })}
            <div className="flex px-2 lg:px-3 xl:px-5">
              <Link
                to={"/blog/what-is-kokopelli"}
                activeClassName="text-primary"
                className="px-1 py-2 my-auto text-sm text-white border border-dashed rounded-full cursor-pointer lg:px-3 md:text-md xl:text-lg border-orange hover:border-primary siteFont"
              >
                What is the Kokopelli?
              </Link>
            </div>
          </div>

          <div
            className={`${
              navToggle ? "hidden" : ""
            } flex px-2 pl-2 lg:pl-2 md:pr-0`}
          >
            <Link
              to="/contact"
              className="px-6 py-2 my-auto transition duration-300 ease-in-out rounded-full cursor-pointer lg:px-4 bg-orange hover:bg-darkOrange"
            >
              <div className="my-auto text-white capitalize siteFont">
                contact us
              </div>
            </Link>
          </div>

          {/* Mobile Hamburger Button */}
          {!navToggle ? (
            <div
              onClick={() => setNavToggle(true)}
              className="px-2 my-auto lg:hidden"
            >
              <MobMenu width="24" height="24" />
            </div>
          ) : (
            <>
              {navToggle && (
                <div className="absolute top-0 bottom-0 left-0 right-0 z-10 w-screen h-screen px-3 py-2 bg-black siteFont">
                  <div className="flex justify-between mb-8">
                    <div className="flex-1">
                      <div className="w-24">
                        <Link to="/">
                          <img src={logo} alt="Kokopelli Logo" />
                        </Link>
                      </div>
                    </div>
                    <div
                      onClick={() => setNavToggle(false)}
                      className="flex items-center justify-center pr-3 my-auto cursor-pointer"
                    >
                      <p className="mr-2 text-sm text-white uppercase">
                        Close menu
                      </p>
                      <CloseIcon width="16" height="16" />
                    </div>
                  </div>
                  <div className="flex flex-col h-full">
                    {mobileNav.map((nav, index) => {
                      return !nav.nestedLinks ? (
                        <Link
                          key={index}
                          to={nav.route}
                          className="my-2 text-2xl font-bold text-center text-white capitalize sm:text-4xl"
                        >
                          {nav.title}
                        </Link>
                      ) : (
                        <div className="flex flex-col" key={index}>
                          <Link
                            to={nav.route}
                            className="my-1 text-2xl font-bold text-center text-white capitalize sm:text-4xl"
                          >
                            {nav.title}
                          </Link>
                          <div className="flex flex-col w-9/12 p-4 mx-auto my-2 bg-gray-700 rounded-md sm:my-2">
                            {nav.nestedLinks.map((item, i) => (
                              <Link
                                to={item.route}
                                className="py-1 text-lg text-center text-white capitalize cursor-pointer sm:text-xl hover:text-blue-300"
                              >
                                {item.title}
                              </Link>
                            ))}
                          </div>
                        </div>
                      )
                    })}
                    <Link
                      to={"/blog/what-is-kokopelli"}
                      activeClassName="text-primary"
                      className="py-2 text-2xl font-bold text-center text-white capitalize border border-dashed rounded-full sm:text-4xl border-orange"
                    >
                      What is the Kokopelli?
                    </Link>
                    {/* <Link
                    to="/contact"
                    className="text-2xl font-bold text-center text-white capitalize sm:text-4xl"
                  >
                    contact us
                  </Link> */}
                  </div>
                </div>
              )}
            </>
          )}
          {customNav && banner && (
            <>
              {/* Desktop */}
              <object
                type="image/svg+xml"
                data={HolidayBannerImage}
                className="hidden holiday-image md:block"
              >
                banner
              </object>
              {/* <img
                src={HolidayBannerImage}
                loading="eager"
                className="hidden holiday-image md:block"
              /> */}
              {/* Mobile */}
              <object
                type="image/svg+xml"
                data={HolidayBannerImageMobile}
                className="holiday-image md:hidden"
              >
                banner
              </object>
              {/* <img
                src={HolidayBannerImageMobile}
                loading="eager"
                className="holiday-image md:hidden"
              /> */}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Header
