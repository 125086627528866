import React, { useContext } from "react"
import PropTypes from "prop-types"

// Components
import Header from "./header"
import Footer from "./footer"
import ContactFormModal from "./contactFormModal"

// Context API data
import { AppContext } from "../store/AppContext"

const Layout = ({ children, home }) => {
  const data = useContext(AppContext)
  const customNav = data?.state?.customNav
  const topBanner = data?.state?.topBanner
  const banner = data?.state?.banner
  return (
    <>
      {data?.state?.display && <ContactFormModal toggle={data?.toggle} />}
      <Header customNav={customNav} topBanner={topBanner} banner={banner} />
      <main
        className={
          !home ? `main ${customNav && topBanner ? "holiday-graphic" : ""}` : ""
        }
      >
        {children}
      </main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
