import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import RichTextRenderer from "./RichTextRenderer"
import { BLOCKS } from "@contentful/rich-text-types"

import tel from "../assets/tel.svg"
import mail from "../assets/mail.svg"
import location from "../assets/location.svg"
import imageBadge from "../assets/img-badge.svg"
import {
  FacebookIcon,
  InstagramIcon,
  LinkedIn,
  PinterestIcon,
  TwitterIcon,
  YoutubeIcon,
} from "./icons"

const navOptions = [
  {
    title: "Who we are",
    route: "/our-company",
  },
  {
    title: "Our Work",
    route: "/our-work",
  },
  {
    title: "For Government",
    route: "/government",
  },
  {
    title: "Blog",
    route: "/blog",
  },
  {
    title: "Contact Us",
    route: "/contact",
  },
]

const services = [
  { title: "Complete marketing Strategy", route: "/services/marketing" },
  { title: "Design", route: "/services" },
  { title: "Video Production", route: "/services/video-production" },
  {
    title: "Website",
    route: "/services/website-development",
  },
]
// Options of RichTextRenderer
const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
  },
}

const Footer = () => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      layout: contentfulWebsiteLayout {
        companyTelNo
        companyTelDetails
        companyEmail
        companyAddress {
          json
        }
        companyAddress2 {
          json
        }
        companyAddress3 {
          json
        }
        facebookLink
        instagramLink
        linkedInLink
        pinterestLink
        twitterLink
        youtubeLink
      }
    }
  `)

  const {
    companyTelNo,
    companyTelDetails,
    companyEmail,
    companyAddress,
    companyAddress2,
    companyAddress3,
    facebookLink,
    instagramLink,
    linkedInLink,
    pinterestLink,
    twitterLink,
    youtubeLink,
  } = data.layout

  const year = new Date().getFullYear()

  return (
    <div className="max-w-6xl px-4 py-2 mx-auto mt-8 bg-black md:mt-16 md:px-8 xl:px-0">
      <div className="flex-wrap justify-between block mb-4 md:mb-16 md:flex">
        <div className="w-full mb-5 md:w-1/2 lg:w-1/3 md:mb-0">
          <div className="flex mt-3">
            <img src={tel} alt="telephone" loading="lazy" />
            <div className="ml-3 sm:ml-5">
              <p className="mb-1 text-white md:text-lg">
                Phone:{" "}
                <a
                  href={`tel:${companyTelNo}`}
                  className="font-bold hover:text-primary"
                >
                  {companyTelNo}
                </a>
              </p>
              <p className="text-sm text-gray-500">{companyTelDetails}</p>
            </div>
          </div>
          <div className="flex mt-3">
            <img src={mail} alt="email" loading="lazy" />
            <div className="inline-block my-auto ml-3 sm:ml-5">
              <a
                href={`mailto:${companyEmail}`}
                className="font-bold text-white md:text-lg hover:text-primary"
              >
                {companyEmail}
              </a>
            </div>
          </div>
          <div className="flex items-start mt-3">
            <img src={location} alt="location" loading="lazy" />
            <div className="ml-3 text-white md:text-lg sm:ml-5">
              {RichTextRenderer(companyAddress.json, options)}
              <br />
              {RichTextRenderer(companyAddress2.json, options)}
              <br />
              {RichTextRenderer(companyAddress3.json, options)}
            </div>
          </div>
        </div>

        <div className="justify-around w-full px-1 my-6 md:w-1/2 lg:w-2/5 md:flex sm:my-0">
          <div className="w-full px-2 mb-8 md:w-3/5">
            <p className="w-full mb-4 text-2xl font-bold tracking-tight text-white siteFont">
              Our Services
            </p>
            <div className="flex flex-wrap md:block text-greyTxt">
              {services.map((service, index) => (
                <Link
                  to={service.route}
                  key={index}
                  className="block w-1/2 mb-2 text-base capitalize Gray md:w-full md:text-lg hover:text-primary"
                >
                  {service.title}
                </Link>
              ))}
            </div>
          </div>
          <div className="px-2">
            <p className="w-full mb-4 text-2xl font-bold tracking-tight text-white siteFont">
              About Us
            </p>
            <div className="flex flex-wrap md:block">
              {navOptions.map((nav, index) => (
                <Link
                  to={nav.route}
                  key={index}
                  className="block w-1/2 mb-2 text-base capitalize Gray md:w-full md:text-lg hover:text-primary"
                >
                  {nav.title}
                </Link>
              ))}
            </div>
          </div>
        </div>

        <div className="w-full mt-4 lg:mt-0 lg:w-1/4">
          <div className="flex justify-center">
            <img src={imageBadge} alt="badge" loading="lazy" />
          </div>
          <div className="flex justify-center mt-6">
            <a
              href={facebookLink}
              target="_blank"
              rel="noreferrer"
              className="p-2 mx-1 border border-gray-700 rounded-full facebookIcon"
            >
              <FacebookIcon height="15" width="15" />
            </a>
            <a
              href={instagramLink}
              target="_blank"
              rel="noreferrer"
              className="p-2 mx-1 border border-gray-700 rounded-full instagramIcon"
            >
              <InstagramIcon height="15" width="15" />
            </a>
            <a
              href={linkedInLink}
              target="_blank"
              rel="noreferrer"
              className="p-2 mx-1 border border-gray-700 rounded-full linkedinIcon"
            >
              <LinkedIn height="15" width="15" />
            </a>
            <a
              href={pinterestLink}
              target="_blank"
              rel="noreferrer"
              className="p-2 mx-1 border border-gray-700 rounded-full pinterestIcon"
            >
              <PinterestIcon height="15" width="15" />{" "}
            </a>
            <a
              href={twitterLink}
              target="_blank"
              rel="noreferrer"
              className="p-2 mx-1 border border-gray-700 rounded-full twitterIcon"
            >
              <TwitterIcon height="15" width="15" />
            </a>
            <a
              href={youtubeLink}
              target="_blank"
              rel="noreferrer"
              className="p-2 mx-1 border border-gray-700 rounded-full youtubeIcon"
            >
              <YoutubeIcon height="15" width="15" />
            </a>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center mb-2 md:flex-row">
        <p className="text-sm font-hairline uppercase Gray md:mx-2">
          copyright &copy; {year} Kokopelli Pictures LLC.
        </p>
        <Link
          to="/privacy-policy"
          className="text-sm font-hairline uppercase Gray md:mx-2 hover:underline"
        >
          Privacy policy
        </Link>
      </div>
      <p className="mb-3 text-sm font-hairline text-center uppercase Gray md:mx-2">
        All rights reserved. Kokopelli is a registered trademark of Kokopelli
        Pictures LLC.
      </p>
    </div>
  )
}

export default Footer
