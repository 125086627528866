import React from "react"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const defaultOptions = {
  renderNode: {
    // add id to h2 elements to be able to link to them.
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2 id={node.content[0].value}>{children}</h2>
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <p className="my-4">{children}</p>,
    [BLOCKS.HR]: () => <hr className="my-6 md:my-16 xl:mx-auto" />,
    [INLINES.HYPERLINK]: (node, children) => {
      const { content, data } = node
      return (
        <a
          className="cursor-pointer text-blue-500 hover:underline hover:text-blue-700"
          href={data.uri}
          target="_blank"
          rel="noreferrer"
        >
          {content[0].value}
        </a>
      )
    },
  },
  renderMark: {
    [MARKS.UNDERLINE]: text => <u>{text}</u>,
  },
}

export default (json, options) =>
  documentToReactComponents(json, options || defaultOptions)
