import React from "react"

const Modal = ({ close, color, children }) => {
  return (
    <div className="overlay" onClick={() => close(false)}>
      <div
        className={`modal-content ${color ? "black-bg" : ""}`}
        onClick={e => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  )
}

export default Modal
