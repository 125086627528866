import React, { useState, useRef } from "react"
// Assets
import SuccessRocket from "../../assets/successRocket.svg"
import { Spinner } from "../icons"
// Styles
import "../PricingCalculator/styles.scss"
// Assets
import BoxBG from "../../assets/boxBG.svg"
import { contactFormData } from "./contactFormData"

const ContactForm = () => {
  // Toggle for section background image
  const bgImage = true

  const formRef = useRef(null)
  const [loading, setLoading] = React.useState(false)
  const [status, setStatus] = React.useState(null)
  const [form, setForm] = useState({
    "form-name": "contactForm",
    firstName: "",
    lastName: "",
    email: "",
    organization: "",
    jobTitle: "",
    estimatedBudget: "",
    workDescription: "",
  })

  const formSubmit = e => {
    e.preventDefault()
    setLoading(true)
    fetch("https://submit-form.com/UmD6zG37", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        "Form Name": "contactForm",
        "First Name": form.firstName,
        "Last Name": form.lastName,
        Email: form.email,
        Organization: form.organization,
        "Job Title": form.jobTitle,
        "Estimated Budget": form.estimatedBudget,
        "Work Description": form.workDescription,
      }),
    })
      .then(() => {
        setStatus("success")
      })
      .catch(error => alert(error))
      .finally(() => {
        setLoading(false)
      })
  }

  return status === "success" ? (
    <div className="w-full p-4 text-center text-white md:p-8">
      <h2 className="mb-8 text-xl font-medium uppercase siteFont md:text-3xl">
        Thank You for reaching out!
      </h2>
      <p className="w-full mx-auto md:w-10/12">
        We typically respond back within one business day.
      </p>

      <img
        src={SuccessRocket}
        alt="success rocket"
        className="w-32 mx-auto mt-8 md:w-48"
      />
    </div>
  ) : (
    <div className="relative px-4 my-4 text-white md:px-8 md:py-6">
      {bgImage && (
        <object
          type="image/svg+xml"
          data={BoxBG}
          className="absolute top-0 bottom-0 left-0 right-0 w-full -z-10"
        >
          bg image
        </object>
      )}
      <h2 className="mb-6 text-2xl font-bold uppercase md:text-3xl siteFont">
        Let's get started!
      </h2>
      <form
        onSubmit={formSubmit}
        name="contactForm"
        method="POST"
        ref={formRef}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        className="contact modal"
      >
        <input type="hidden" name="form-name" value="contactForm" />
        <div className="flex flex-wrap align-end">
          {contactFormData.map((node, index) => {
            if (node.type === "radio") {
              return (
                <fieldset key={index} className="flex flex-col w-full my-4">
                  <legend className="mb-2">{node.label}</legend>
                  <div className="flex flex-wrap justtify-between">
                    {node.options.map((item, i) => (
                      <div className="w-1/2 my-2 md:my-0 md:w-1/4" key={i}>
                        <label className="flex items-center cursor-pointer">
                          <input
                            id={item.value}
                            name={node.id}
                            value={item.label}
                            type="radio"
                            className="mr-4"
                            onChange={e => {
                              setForm({ ...form, [node.id]: e.target.value })
                            }}
                            required
                          />
                          {item.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              )
            } else
              return (
                <div
                  key={index}
                  className={`w-full flex flex-col my-4 ${
                    node.size !== "large" ? "md:w-1/2" : ""
                  }`}
                >
                  <label className="mb-2">{node.label}</label>
                  {node.type === "textarea" ? (
                    <textarea
                      rows="4"
                      cols="5"
                      resize="none"
                      id={node.id}
                      name={node.id}
                      value={form.workDescription}
                      onChange={e =>
                        setForm({ ...form, [node.id]: e.target.value })
                      }
                      required
                    ></textarea>
                  ) : (
                    <input
                      id={node.id}
                      name={node.id}
                      value={form[node.id]}
                      type={node.type ? node.type : "text"}
                      className={`w-full ${!node.size ? "md:w-11/12" : ""}`}
                      rows={node.type === "textarea" ? "5" : ""}
                      onChange={e => {
                        setForm({ ...form, [node.id]: e.target.value })
                      }}
                      required
                    />
                  )}
                </div>
              )
          })}
          <div className="flex justify-end w-full">
            <button
              className={`px-6 py-2 text-center text-white uppercase transition duration-300 ease-in-out rounded-full text-md md:block md:text-lg focus:outline-none  siteFont ${
                loading ? "bg-gray-800" : "bg-orange hover:bg-darkOrange"
              }`}
              type="submit"
              disabled={loading}
            >
              {loading ? <Spinner /> : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
